import './hero.css';
import backgroumd from '../../assets/background.jpg';
import Button from '../Button.jsx';
import Arrow from '../../assets/Arrow';
import Place from '../../assets/Place';
import { HashLink as Link } from 'react-router-hash-link';

const Hero = () => {
    return (
        <div>
            <div className="hero-text" id='hero'>
                <h1>Hi, I'm <span style={{ color: "#78E98A" }}>Eilon</span></h1>
                <p>I'm a junior Full Stack developer ! with years of self studying and discovering new technologies ...</p>
                <p>and also a Computer Science student</p>
            </div>
            <img className='hero' src={backgroumd} alt="background" />
            <Link to="#contacts" smooth>
                <Button ></Button>
            </Link>
            <Arrow></Arrow>
            <Place></Place>
        </div>
    );
}

export default Hero;