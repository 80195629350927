import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import validator from 'validator';
import "./contacts.css";

const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;


const Contacts = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY)
            .then((result) => {
                alert("Message sent, I'll get back to you soon", result.text);
            }, (error) => {
                console.log(error.text);
            });

        resetForm();
    };

    const resetForm = () => {
        form.current.reset();
    }

    const validateForm = () => {
        if (form.current.from_name.value === '') {
            alert("Please enter your name");
            return false;
        }
        if (!validator.isEmail(form.current.from_email.value)) {
            alert("Invalid email");
            return false;
        }
        if (form.current.message.value === '') {
            alert("Please enter your message");
            return false;
        }
        return true;
    }


    return (
        <div className="contacts" id='contacts'>
            <div className="contacts-text-container">
                <h1 className="contacts-title">Let's work together!</h1>
                <h2>contact me</h2>
                <form ref={form} className="contacts-form">
                    <input type="text" placeholder="Full Name" name='from_name' />
                    <input type="text" placeholder="Email" name='from_email' />
                    <input type="text" placeholder="Description" name='message' />
                    <button onClick={sendEmail} >Submit</button>
                </form>
            </div>
            <div className="contacts-img-container">
                {/* <img className="contacts-img" src={img} alt="LOADING..." /> */}
            </div>
        </div>
    );
}

export default Contacts;