import PlaceIcon from '@mui/icons-material/Place';

const Place = () => {

    return (
        <div className="place">
            <PlaceIcon />
            <p style={{fontSize:"inherit"}}>Galápagos Islands</p>
        </div>
    );
}

export default Place;