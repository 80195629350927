const Arrow = () => {
    const style = {
        position: "absolute",
        top: "calc(100% - 112px)",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    }

    return (
        <div style={style}>
            <p>see more</p>
            <svg width="24" height="172" viewBox="0 0 24 172" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.9393 171.061C11.5251 171.646 12.4749 171.646 13.0607 171.061L22.6066 161.515C23.1924 160.929 23.1924 159.979 22.6066 159.393C22.0208 158.808 21.0711 158.808 20.4853 159.393L12 167.879L3.51472 159.393C2.92893 158.808 1.97918 158.808 1.3934 159.393C0.807612 159.979 0.807612 160.929 1.3934 161.515L10.9393 171.061ZM10.5 0L10.5 170H13.5L13.5 0L10.5 0Z" fill="white" fillOpacity="0.7" />
            </svg>
        </div>
    );
}

export default Arrow;