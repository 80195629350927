import "./header.css";
import { HashLink as Link } from "react-router-hash-link";
const Header = () => {
    return (
        <header>
            <div className="logo-container">
                <Link className="logolink" to="#hero" smooth>
                    <h1 className="logo">EILON</h1>
                </Link>
            </div>
            <nav className="navbar">
                <Link to='#projects' smooth>#projects</Link>
                <Link to='#aboutme' smooth>#about_me</Link>
                <Link to='#contacts' smooth>#contacts</Link>
            </nav>
        </header>
    );
}

export default Header;