import "./aboutme.css";
import AboutMeLogo from "../../assets/Maskgroup.png";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import { HashLink as Link } from "react-router-hash-link";

const AboutMe = () => {
    return (
        <div id="aboutme" className="about-me_container">
            <div className="about-me_logo_container">
                <img src={AboutMeLogo} alt="About Me Logo" />
            </div>
            <div className="about-me_text_container">
                <h1>About Me</h1>
                <p>
                    Determined and self-taught programmer with years of self-studying, developing, discovering new technologies,
                    mainly focused on Web Development and DevOps technologies.
                    Great inter-personal abilities, self-managed and self-learner and highly motivated.
                    Also a second year computer science student.
                </p>
                <div className="about-me_icons">
                    <LinkedInIcon className="icon" fontSize="large" style={{ cursor: "pointer" }} onClick={() => {
                        window.open("https://www.linkedin.com/in/eilon-alter/");
                    }} />
                    <GitHubIcon className="icon" fontSize="large" style={{ cursor: "pointer" }} onClick={() => {
                        window.open("https://github.com/eilonALT");
                    }} />
                    <Link to="#contacts" smooth>
                        <EmailIcon fontSize="large" style={{ cursor: "pointer" }} />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default AboutMe;