import './App.css';
import { BrowserRouter as Router} from 'react-router-dom';
import Header from './components/header/Header.jsx';
import Hero from './components/hero/Hero.jsx';
import Projects from './components/projects/Projects.jsx';
import AboutMe from './components/aboutme/AboutMe';
import Contacts from './components/contacts/Contacts';

const App = () => {
  return (
    <div className="App">
      <Router>
        <Header />
        <Hero />
        <Projects />
        <AboutMe />
        <Contacts />
      </Router>
    </div>
  );
}

export default App;
