import "./projectCard.css";

const ProjectCard = ({ name, img, description, tools, link }) => {
    return (
        <div className="project-card">
            <div className="project-card__img">
                <img className="project_img" src={img} alt={name} />
            </div>
            <p className="project-card__name">{name}</p>
            <p className="project-card__description">{description}</p>
            <div className="project-card__tools">
                {tools.map((tool, index) => (
                    <span className="label" key={index}>{tool}</span>
                ))}
            </div>
            <button className="project-card__button" onClick={() => {
                window.open(link);
            }}>{`GitHub <~>`}</button>
        </div>
    );
}

export default ProjectCard;