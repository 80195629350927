import "./projects.css"
import ProjectCard from "./projectCard/ProjectCard"
import shoppingImg from "../../assets/shopping.png"
import portfolioImg from "../../assets/portfolio.png"

const Projects = () => {
    return (
        <div className="projects">
            <h1>Projects</h1>
            <div id="projects" className="projects__container">
                <ProjectCard name={"Supermarket"} description={"This is a full-stack project which include both Front and Back development technologies. The app let a client to create a user and make an order with the site products. also there is an admin dashboard for creating new products and maintain the site options. The project supports private routing and manage state with NgRx."}
                    img={shoppingImg} tools={["Angular", "Node.js", "Express", "MongoDB"]} link={"https://github.com/eilonALT/Shopping-Online"} />
                <ProjectCard name={"Vacations-App"} description={"This is a full-stack project which include both Front and Back development technologies, running on docker-containers environment. The app let a client to create a user and follow vacations created and maintained by an admin dashboard (also implemented). The project supports private routing and manage state with Redux-toolkit,also supports real time information with web socket."}
                    img={'https://raw.githubusercontent.com/eilonALT/VacationsApp/master/readme.png'} tools={["React", "Node.js", "Express", "MySQL"]} link={"https://github.com/eilonALT/VacationsApp"} />
                <ProjectCard name={"Portfolio"} description={"This is a full-stack project which include both Front and Back development technologies. The app let a client to create a user and make an order with the site products. also there is an admin dashboard for creating new products and maintain the site options. The project supports private routing and manage state with NgRx."}
                    img={portfolioImg} tools={["React", "Figma", "CSS", "UI/UX"]} link={"https://github.com/eilonALT/Portfolio"} />
            </div>
        </div>
    )
}

export default Projects